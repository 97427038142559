import React from 'react'
import {graphql} from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'

import {responsiveTitle1} from '../components/typography.module.css'

// export const query = graphql`
//   query ThanksPageQuery {
//     about: allSanityAbout(
//       limit:1
//     ) {
//       edges {
//         node {
//           id
//           title
//           _rawBody
//           }
//       }
//     }
//   }
// `

const ThanksPage = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const about = (data || {}).about
  return (
    <Layout>
      <SEO title='Thanks' />
      <Container>
      <h1>thank you</h1>
      </Container>
    </Layout>
  )
}

export default ThanksPage
